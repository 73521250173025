import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Shortener from './pages/Shortener';
import SamuelValidator from "./pages/SamuelValidator";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/shorter" element={<Shortener />} />
                <Route path="/shorter/:hash" element={<Shortener />} />
                <Route path="/samuel" element={<SamuelValidator />} />
            </Routes>
        </Router>
    );
}

export default App;