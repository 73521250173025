import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import LinkShortener from '../components/LinkShortener';

function Shortener() {
    const { hash } = useParams();
    const [redirectError, setRedirectError] = useState(false);

    useEffect(() => {
        if (hash) {
            fetch(`https://api.duperez.dev/api/Shorters/hash/${hash}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response)
                    if (response.ok) {
                        return response.json(); // Processa a resposta JSON se tudo estiver OK
                    } else {
                        console.log("Erro na resposta da API:", response.status);
                        setRedirectError(true);
                        return null; // Ou um erro customizado para tratar adequadamente
                    }
                })
                .then(data => {
                    if (data && data.origin) {
                        // Verifica se a URL original possui http/https, caso contrário, adiciona "https://"
                        window.location.href = data.origin.startsWith('http') ? data.origin : `https://${data.origin}`;
                    } else if (!data) {
                        setRedirectError(true);
                    }
                })
                .catch(() => setRedirectError(true));
        }
    }, [hash]);


    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            {hash ? (
                redirectError ? (
                    <p>Link não encontrado!</p>
                ) : (
                    <p>Redirecionando...</p>
                )
            ) : (
                // Renderiza o componente LinkShortener se não houver hash
                <LinkShortener />
            )}
        </div>
    );
}

export default Shortener;
