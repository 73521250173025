import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

function LinkShortener() {
    const [link, setLink] = useState('');
    const [shortenedLink, setShortenedLink] = useState(null);

    const shortenLink = () => {
        fetch('https://api.duperez.dev/api/Shorters', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: 0, origin: link, hash: "string" })
        })
            .then(response => response.json())
            .then(data => {
                if (data.hash) {
                    setShortenedLink(`https://duperez.dev/shorter/${data.hash}`);
                } else {
                    alert('Erro ao encurtar o link.');
                }
            })
            .catch(() => alert('Erro ao encurtar o link.'));
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1 style={{color: 'white'}}>Encurtador de Links</h1>
            <p style={{color: 'white'}}>Transforme seus links longos em links fáceis de compartilhar.</p>
            <br/>
            <input
                type="text"
                placeholder="Insira o link aqui..."
                value={link}
                onChange={(e) => setLink(e.target.value)}
                style={{
                    padding: '10px',
                    width: '80%',
                    fontSize: '16px',
                    marginBottom: '20px',
                    boxSizing: 'border-box'
                }}
            />
            <br />
            <button
                onClick={shortenLink}
                style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    color: 'white',
                    backgroundColor: '#007bff',
                    border: 'none',
                    cursor: 'pointer',
                    width: '200px'
                }}
            >
                Encurtar Link
            </button>

            {shortenedLink && (
                <div style={{
                    marginTop: '20px',
                    padding: '20px',
                    backgroundColor: '#282c34',
                    borderRadius: '10px',
                    textAlign: 'center'
                }}>
                    <p>Link encurtado: <a href={shortenedLink} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', color: '#61dafb' }}>{shortenedLink}</a></p>
                    <p className="qrCode-text">Ou use o Qr Code</p>
                    <QRCodeCanvas value={shortenedLink} size={128} style={{ margin: '10px auto' }} />
                    <p className="advice-text">O link dura até 48 horas ou menos, dependendo da demanda</p>
                </div>
            )}
        </div>
    );
}

export default LinkShortener;
