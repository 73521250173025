import React from 'react';

function Home() {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Oi, eu sou o Duperez</h1>
            <p>Aqui eu guardo alguns projetos que eu faço.</p>
        </div>
    );
}

export default Home;
