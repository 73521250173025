import React, { useState, useEffect } from 'react';
import './pages.css';

function SamuelValidator() {
    const [urlInput, setUrlInput] = useState('');
    const [result, setResult] = useState('');
    const [url, setUrl] = useState('');
    const [resultClass, setResultClass] = useState('checking');
    const [history, setHistory] = useState([]); // Estado para o histórico

    // Carrega o histórico do localStorage quando o componente é montado
    useEffect(() => {
        const savedHistory = JSON.parse(localStorage.getItem('history')) || [];
        setHistory(savedHistory);
    }, []);

    const checkUrl = async () => {
        if(urlInput.length === 0){
            return;
        }
        const baseUrl = 'https://d4ylightd3mon.com.br/';
        const fullUrl = baseUrl + urlInput;
        const proxyUrl = `/check-url?url=${encodeURIComponent(fullUrl)}`;

        setResult('Verificando...');
        setResultClass('checking');

        try {
            const response = await fetch(proxyUrl);
            const data = await response.json();

            if (data.status === 404) {
                setResult('URL inválida!');
                setResultClass('invalid');
            } else {
                setResult('URL válida!');
                setResultClass('valid');
                setUrl(fullUrl);
            }
        } catch (error) {
            setResult('Erro ao verificar a URL.');
            setResultClass('invalid');
        }

        // Adiciona o novo input no início do array e salva no localStorage
        setHistory(prevHistory => {
            const newHistory = [urlInput, ...prevHistory];
            localStorage.setItem('history', JSON.stringify(newHistory));
            return newHistory;
        });
    };

    return (
        <div className="main-container">
            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet"/>
            <div className="container">
                <h1>Valide o site do samuel</h1>
                <input
                    type="text"
                    value={urlInput}
                    onChange={(e) => setUrlInput(e.target.value)}
                    placeholder="exemplo: inf1nid4d3_"
                    onKeyDown={(e) => {
                        if ((e.key === 'Enter' || e.key === 'Enter')) {
                            checkUrl()
                        }
                    }}
                />
                <button disabled={urlInput.length === 0 } onClick={checkUrl}>Verificar</button>
                <div id="result" className={resultClass}>
                    {result}
                    <br/>
                    <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                </div>
                <footer>
                    Site fan made, feito por <a href="https://discord.com/users/duperez#0000" target="_blank"
                                                rel="noopener noreferrer">Duperez</a>
                </footer>
            </div>

            {/* Histórico de tentativas */}
            {history.length >= 0 && (
                <div className="history-card">
                    <h2>Histórico de Tentativas</h2>
                    <ul>
                        {history.map((input, index) => (
                            <li key={index}>{input}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default SamuelValidator;
